<template>
<div>
    
    <validation-observer ref="templateRules">
        <b-form>
             <b-card-actions action-collapse title="Basic Info">
                <b-row>
                    <b-col md="12">
                      <b-form-group>
                            <label>Title</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="title"
                                >
                                <b-form-input
                                    v-model="input.title"
                                  
                                    placeholder="title"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                         <b-form-group>
                            <label>Type</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="types"
                                >
                                 <v-select
                            v-model="input.types"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            
                            :options="types"
                    />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                           
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                            <label>Subject</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="subject"
                                >
                                <b-form-input
                                    v-model="input.subject"
                                  
                                    placeholder="Subject"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                    </b-col>
                     <b-col md="12" class="q-message">
                        <b-form-group>
                        <label>Message</label>
                          <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="message"
                                >
                        <quill-editor
                        style="height: 350px"
                        v-model="input.message"
                        
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        
                        </b-form-group>
                    </b-col>
                   
                    <b-col cols="12">
                        <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
             </b-card-actions>


        </b-form>
        
    </validation-observer>
</div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup,BFormSpinbutton,BTable,BPagination,BFormInvalidFeedback
} from 'bootstrap-vue'
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,BBadge, BSidebar, VBToggle
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/* eslint-disable global-require */

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import router from '@/router'
import { quillEditor } from 'vue-quill-editor'


export default {
    components: {
         vSelect, BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup,BFormSpinbutton,BTable,BPagination,BFormInvalidFeedback,
         BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,BBadge, BSidebar, VBToggle,
         BCardActions,ValidationProvider,ValidationObserver,
         quillEditor
    },
    directives: {
   
     
      Ripple,
    },
    data(){
        return {
            types:['text','property'],
            id:router.currentRoute.params.id,
            userData: JSON.parse(localStorage.getItem('userData')),
            input : {
                id:null,
                title: null,
                types:'text',
                subject:null,
                message: null,
                created_by:null
               
            }
        }
    },
    created() {
        if(this.id != undefined){
            this.getData();
        }

    },
   
    
    methods: {
        async getData(){
               let res = await  axios.get('crm/v1/get-template/'+this.id);
               if(res.data.success){
                    this.input = res.data.data;
               }
               
        },
        makeToast(variant = null,title,message) {
            this.$bvToast.toast(message, {
                title: title,
                variant,
                solid: true,
            })
        },
        async validationForm() {
           
            this.$refs.templateRules.validate().then(async success => {
               this.input.created_by = this.userData.id;
               var data = JSON.stringify(this.input);
                let res = {};
                if(success){
                    if(this.id != undefined){
                          res = await  axios.put('crm/v1/update-template/'+this.id,data)
                    }else{
                         res = await  axios.post('crm/v1/add-template',data)
                      

                    }
                     if(!res.data.success){
                            this.makeToast('danger','Error 403',res.data.message);
                        }else{
                            this.$router.push('/crm/template', () => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                    title: `Done`,
                                    icon: 'CoffeeIcon',                 
                                    variant: 'success',
                                    text: `You have successfully updated as .`,
                                    },
                                })
                            });
                        }

                }
            });
        }
    }
}
</script>
<style scoped>
.q-message{
    height: 450px;
}
</style>
